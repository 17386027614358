import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const title = 'PCBA-SMT贴片-电路控制板-电路板设计-电子产品加工-控制板技术方案-杭州谱地新能源科技'
const keyword = 'PCBA,电路,电路板,物联网,软硬件开发,电路控制板,电子产品加工,控制板,控制板技术方案,电路板设计,SMT贴片,谱地,新能源,pudi,物联网系统,物联网系统开发,物联网系统软硬件开发'
const description = "杭州谱地新能源科技有限公司专业从事BLIS,BSM,BSD,盲点监测,并线辅助,PCBA,SMT贴片,电路控制板,电路板设计,电子产品加工,控制板技术方案的生产和销售,欢迎来电咨询。"

const routes = [{
    path: "/",
    name: "Home",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/Home.vue"),
    meta: { 
      title: title,
      keywords: keyword,
      description: description
    }

  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/core",
    name: "Core",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/core.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/productCenter",
    name: "ProductCenter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/productCenter.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/blis",
    name: "Blis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/blis.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/electric",
    name: "Electric",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/electric.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/detail",
    name: "Detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/detail.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/fangan",
    name: "Fangan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/fangan.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/contact.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/pc/news.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  {
    path: "/m/",
    name: "MobileHome",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/mobile/Home.vue"),
    meta: {
      title: title,
      keywords: keyword,
      description: description
    }
  },
  
];
let originPush = VueRouter.prototype.push

// 重写push|replace
// 第一个参数：告诉原来的push方法跳转到哪个路由 ,即原来向push方法传递的参数
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject)
  } else {
    originPush.call(this, location, ()=>{}, ()=>{})
  }
}
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
});

export default router;